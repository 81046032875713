import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps, useMemo } from 'react';
import { renderToString } from 'react-dom/server';

import { parseHtmlToReact } from '@hkexpressairwayslimited/ui';
import classes from './JssRichText.module.scss';

export const JssRichText = ({ field, ...others }: ComponentProps<typeof RichText>) => {
  const sanitizedField = useMemo(
    () =>
      field
        ? {
            ...field,
            value: renderToString(parseHtmlToReact(field.value || '')),
          }
        : field,
    [field]
  );

  return <RichText className={classes.jssRichText} field={sanitizedField} {...others} />;
};
