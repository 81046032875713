"use client";

import "@hkexpressairwayslimited/ui/src/styles/index.scss";

import { LicenseInfo } from "@mui/x-license";
import { envUiConfig } from "./env";

if (envUiConfig.muiLicenseKey) {
  LicenseInfo.setLicenseKey(envUiConfig.muiLicenseKey);
}

//
export * from "./env";

export * from "./constant";
//
export * from "./utils";
//
export * from "./contexts";
//
export * from "./hooks";
//
export * from "./assets";
//
export * from "./themes";
//
export * from "./styles";
//
export * from "./components";
//
export * from "./wa";
