import { AppContext, Image } from '@hkexpressairwayslimited/ui';
import { EditMode, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { ImageProps } from 'next/image';
import { ComponentProps, useContext } from 'react';

export const JssImage = (props: ComponentProps<typeof NextImage>) => {
  const { isInEditor, isPreview, editMode } = useContext(AppContext);
  const imgProps = {
    ...(props.field?.value || {}),
    ...props,
    height:
      props.height || props.width
        ? props.height
        : (props.field?.value as { height?: ImageProps['height'] })?.height,
    width:
      props.height || props.width
        ? props.width
        : (props.field?.value as { width?: ImageProps['width'] })?.width || 16,
  } as ImageProps;
  const { src, ...restEditingProps } = imgProps;

  return isInEditor || isPreview ? (
    <NextImage
      unoptimized={editMode !== EditMode.Metadata && (isInEditor || isPreview)}
      {...restEditingProps}
    />
  ) : (
    <Image {...imgProps} />
  );
};
