import {
  AppContext,
  Pagination as PaginationUi,
  PaginationProps as PaginationUiProps,
} from '@hkexpressairwayslimited/ui';
import {
  Placeholder,
  getChildPlaceholder,
  withDatasourceCheck,
  withPlaceholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { ReactElement, ReactNode, useCallback, useContext, useState } from 'react';

type PaginationProps = ComponentProps & {
  fields: {};
  paginationChildren: ReactElement[];
};

const Pagination = ({ rendering, paginationChildren }: PaginationProps) => {
  const { isInEditor } = useContext(AppContext);
  const childPlaceholder = getChildPlaceholder(rendering, 'pagination-children');
  const [paginationObj, setPaginationObj] = useState<{ start: number; end: number }>({
    start: 0,
    end: 0,
  });

  const handleChange = useCallback((page: number, props: PaginationUiProps) => {
    setPaginationObj({
      start: (page - 1) * (props.itemsPerPage as number),
      end: page * (props.itemsPerPage as number),
    });
  }, []);

  const handleRenderEach = useCallback(
    (component: ReactNode, index: number) => {
      if (index >= paginationObj.start && index < paginationObj.end) {
        return component;
      } else {
        return <div style={{ display: 'none' }}>{component}</div>;
      }
    },
    [paginationObj]
  );

  return (
    <PaginationUi onChange={handleChange} count={childPlaceholder.length}>
      {isInEditor ? (
        <Placeholder name="pagination-children" rendering={rendering} />
      ) : (
        paginationChildren
      )}
    </PaginationUi>
  );
};

const WithPlaceholderPagination = withPlaceholder({
  placeholder: 'pagination-children',
  prop: 'paginationChildren',
})(withDatasourceCheck()<PaginationProps>(Pagination) as React.FunctionComponent);

export default WithPlaceholderPagination;
