import { Box, ChevronRightIcon, EFont, EPixel, Font, SvgIcon } from '@hkexpressairwayslimited/ui';
import { Stack } from '@mui/material';
import {
  Field,
  ImageField,
  Link,
  LinkFieldValue,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssImage } from 'lib/components';

type SliderBannerCardProps = ComponentProps & {
  fields: {
    title: Field<string>;
    content: Field<string>;
    imageIcon: ImageField;
    link: LinkFieldValue;
  };
};

const SliderBannerCard = (props: SliderBannerCardProps): JSX.Element => {
  return (
    <Box
      className={`${props.params?.styles}`}
      sx={{
        backgroundColor: '#fff',
        padding: 0,
        width: '100%',
        '@media (max-width: 900px)': {
          padding: '12px 14px',
        },
      }}
    >
      <Link
        field={props.fields?.link}
        style={{ width: '100%', height: '100%', textDecoration: 'none' }}
      >
        <Stack
          justifyContent="center"
          sx={{ padding: '10px 20px', height: '100%', backgroundColor: '#ECE2ED' }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box
              sx={{
                color: '#702B91',
                '& img': {
                  maxWidth: '100%',
                },
              }}
            >
              <JssImage field={props.fields?.imageIcon} width={1248} />
              <Font
                sx={{ margin: `${EPixel.px12} 0` }}
                fontWeight="fontWeightBold"
                variant={EFont.h1}
              >
                <Text field={props.fields?.title} />
              </Font>
              <Text field={props.fields?.content} />
            </Box>
            <Box
              sx={{
                '@media (max-width: 900px)': {
                  display: 'none',
                },
              }}
            >
              <SvgIcon
                sx={{
                  width: EPixel.px48,
                  height: EPixel.px48,
                  color: '#702B91',
                }}
              >
                <ChevronRightIcon />
              </SvgIcon>
            </Box>
          </Stack>
        </Stack>
      </Link>
    </Box>
  );
};

export default withDatasourceCheck()<SliderBannerCardProps>(SliderBannerCard);
