import { Box, Button, CrossOutlineIcon, EPixel, SvgIcon } from '@hkexpressairwayslimited/ui';
import { Stack } from '@mui/material';
import { Field, Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssRichText } from 'lib/components/JssRichText';
import { useState } from 'react';

type PopupProps = ComponentProps & {
  fields: {
    title: Field<string>;
  };
};
const Popup = (props: PopupProps): JSX.Element => {
  const [isShowModal, setIsShowModal] = useState(true);
  const onCrossClick = () => {
    setIsShowModal(false);
  };
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 999,
        inset: 0,
        alignItems: {
          md: 'center',
          xs: 'flex-end',
        },
        justifyContent: 'center',
        display: isShowModal ? 'flex' : 'none',
      }}
    >
      <Box
        onClick={onCrossClick}
        sx={{ position: 'fixed', zIndex: -1, inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
      ></Box>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          padding: {
            xs: `${EPixel.px24} ${EPixel.px8} ${EPixel.px24} ${EPixel.px16}`,
            md: `${EPixel.px40} ${EPixel.px12} ${EPixel.px40} ${EPixel.px24}`,
          },
          width: {
            md: '448px',
            xs: '100%',
          },
          borderRadius: {
            md: EPixel.px16,
            xs: `${EPixel.px16} ${EPixel.px16} 0 0`,
          },
        }}
      >
        <Box
          sx={{
            height: '100%',
            maxHeight: '80vh',
            padding: {
              xs: `0 ${EPixel.px8} 0 0`,
              md: `0 ${EPixel.px12} 0 0`,
            },
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#702B91',
              bordeRadius: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-corner': {
              background: 'transparent',
            },
          }}
        >
          <Stack
            sx={{ mb: EPixel.px16 }}
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box sx={{ fontSize: '26px', lineHeight: EPixel.px32, fontWeight: 'bolder' }}>
              <JssRichText field={props.fields?.title} />
            </Box>
            <Box sx={{ mt: EPixel.px4, ml: EPixel.px8 }}>
              <Button custom onClick={onCrossClick}>
                <SvgIcon>
                  <CrossOutlineIcon />
                </SvgIcon>
              </Button>
            </Box>
          </Stack>
          <Placeholder name="popup-placeholders" rendering={props.rendering} />
        </Box>
      </Box>
    </Box>
  );
};

export default withDatasourceCheck()<PopupProps>(Popup);
