import { Header, Link as LinkUi, colors } from '@hkexpressairwayslimited/ui';
import {
  Link,
  LinkField,
  Placeholder,
  getChildPlaceholder,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ContentBlockProps = ComponentProps & {
  fields: {
    groupLink: LinkField;
  };
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const NavMenuGroup = ({ fields, rendering }: ContentBlockProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const isPageEditing = sitecoreContext && sitecoreContext.pageEditing;
  const { text, class: className, ...groupLinkProps } = fields?.groupLink.value || {};
  const { NavMenuGroup } = Header;
  const navMenuLinks = getChildPlaceholder(rendering, 'nav-menu-links');

  return (
    <NavMenuGroup
      groupLink={
        isPageEditing ? (
          <Link field={fields?.groupLink} />
        ) : (
          <LinkUi color={colors.neutralBlack} className={className} {...groupLinkProps}>
            {text}
          </LinkUi>
        )
      }
    >
      {navMenuLinks.length > 0 ? <Placeholder name="nav-menu-links" rendering={rendering} /> : null}
    </NavMenuGroup>
  );
};

export default withDatasourceCheck()<ContentBlockProps>(NavMenuGroup);
