import { FooterNavLink as FooterNavLinkUi } from '@hkexpressairwayslimited/ui';
import {
  Link,
  LinkField,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type FooterNavLinkProps = ComponentProps & {
  fields: {
    link: LinkField;
  };
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const FooterNavLink = ({ fields }: FooterNavLinkProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const isPageEditing = sitecoreContext && sitecoreContext.pageEditing;
  const { text, className, href, ...linkProps } = fields?.link.value || {};

  return isPageEditing ? (
    <Link field={fields?.link} />
  ) : (
    <FooterNavLinkUi className={className} href={href}>
      {text}
    </FooterNavLinkUi>
  );
};

export default withDatasourceCheck()<FooterNavLinkProps>(FooterNavLink);
