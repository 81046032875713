import {
  AppContext,
  Booking as BookComponent,
  Box,
  SearchingDetail,
  convertSearchParams,
  getCookieDomain,
} from '@hkexpressairwayslimited/ui';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { format } from 'date-fns';
import Cookies from 'js-cookie';
import { ComponentProps } from 'lib/component-props';
import { endpoints, fetchAPI } from 'lib/services/api';
import { envConfig } from 'lib/services/env';
import { useTransContent } from 'lib/transContent';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useState } from 'react';
type BookingProps = {
  params: {
    campaignCode?: string;
  };
} & ComponentProps;

type AirportCodeMap = {
  [key: string]: string;
};

type FlightRouteMappingSalesProtGroupingItemPort = {
  airport_code: string;
  create_date_time: string;
  created_by: string;
  is_insurance_enabled: string;
  is_pier: boolean;
  market: string;
  time_zone: string;
  update_date_time: string;
  updated_by: string;
  version: string;
};

type FlightRouteMappingSalesProtGroupingItem = {
  market: string;
  ports: FlightRouteMappingSalesProtGroupingItemPort[];
  default_currency: string;
};

type FlightRouteMapping = {
  flight_route_mappings: {
    destination: string[];
    origin: string;
  }[];
  sales_port_grouping: FlightRouteMappingSalesProtGroupingItem[];
};

const getFlightRoute = async () => {
  const result = await fetchAPI(endpoints.flightRouteMapping, {
    method: 'GET',
    cache: 'no-store',
    headers: { 'Content-Type': 'application/json' },
  });
  return result;
};

const Booking = ({ params }: BookingProps): JSX.Element => {
  const { t } = useTransContent();
  const router = useRouter();
  const [flightRouteOptions, setFlightRouteOptions] = useState<FlightRouteMapping | null>(null);
  const portsCode = Object.values(flightRouteOptions?.flight_route_mappings || []).map(
    (e) => e.origin
  );
  const salesPort = Object.values(flightRouteOptions?.sales_port_grouping || []).map(
    (e) => e.market
  );
  const airportCode: AirportCodeMap = useMemo(() => {
    const portNames =
      portsCode?.reduce((acc, port) => {
        acc[port] = t(`airportCodeToCityName.${port}`) as string;
        return acc;
      }, {} as AirportCodeMap) || {};

    const marketNames =
      salesPort?.reduce((acc, market) => {
        acc[market] = t(`marketCodeName.${market}`) as string;
        return acc;
      }, {} as AirportCodeMap) || {};

    return { ...portNames, ...marketNames };
  }, [portsCode, salesPort, t]);

  useEffect(() => {
    getFlightRoute().then((response) => {
      setFlightRouteOptions(response);
    });
  }, []);
  const handleBookingTripSubmit = (data: SearchingDetail) => {
    const query = convertSearchParams(data);

    router.push(`${envConfig.bookingHost}/${router.locale}/flight-booking/select?${query}`);
  };
  const handleManageMyBookingSubmit = (data: any) => {
    const { BookingRef, FirstName, LastName } = data.data;
    sessionStorage.setItem(
      'tripParams',
      JSON.stringify({
        bookingRef: BookingRef.toUpperCase(),
        firstName: FirstName,
        lastName: LastName,
      })
    );
    const params = new URLSearchParams({
      bookingRef: BookingRef.toUpperCase(),
      firstName: FirstName,
      lastName: LastName,
      isFromRetrieveBooking: 'true',
    });
    router.push(
      `${envConfig.bookingHost}/manage-my-booking/my-trips/${BookingRef.toUpperCase()}?${params}`
    );
  };
  const handleManageMyBookingLink = () => {
    router.push(`${envConfig.bookingHost}/manage-my-booking/my-trips`);
  };
  const handleCheckInSubmit = (data: any) => {
    const { BookingRef, FirstName, LastName } = data.data;

    sessionStorage.setItem(
      'tripParams',
      JSON.stringify({
        bookingRef: BookingRef.toUpperCase(),
        firstName: FirstName,
        lastName: LastName,
        isFromRetrieveBooking: 'true',
      })
    );
    const params = new URLSearchParams({
      bookingRef: BookingRef.toUpperCase(),
      firstName: FirstName,
      lastName: LastName,
    });
    router.push(
      `${envConfig.bookingHost}/${router.locale}/manage-my-booking/my-trips/${BookingRef.toUpperCase()}?${params}`
    );
  };
  const handleFlightStatusSubmit = (data: any) => {
    const { SearchBy, FlightNumber, DepartDate, From, To } = data.data;
    const url =
      SearchBy === 'Flight number'
        ? `${envConfig.bookingHost}/${router.locale}/flight-status/?searchByFlightNum&flightNum=${FlightNumber}&DepartDate=${format(DepartDate[0], 'yyyy-MM-dd')}`
        : `${envConfig.bookingHost}/${router.locale}/flight-status/?searchByRoute&From=${From}&To=${To}`;

    router.push(url);
  };

  const i18nContent = {
    booking: {
      bookATrip: t('web.home.bookATrip'),
      manageMyBooking: t('web.home.manageMyBooking'),
      checkIn: t('web.home.checkIn'),
      flightStatus: t('web.home.flightStatus'),
    },
    bookTrip: {
      trip: t('web.home.bookATrip.trip'),
      passengers: t('web.home.bookATrip.passengers'),
      passengersAdult: t('web.home.bookATrip.passengers.adults'),
      passengersChild: t('web.home.bookATrip.passengers.children'),
      passengersChildTip: t('web.home.bookATrip.passengers.childTip'),
      passengersInfant: t('web.home.bookATrip.passengers.infants'),
      passengersInfantTip: t('web.home.bookATrip.passengers.infantTip'),
      passengersPopUpTitle: t('web.home.bookATrip.passengers.popUpTitle'),
      promoCodeOptional: t('web.home.bookATrip.promoCodeOptional'),
      date: t('web.home.bookATrip.date'),
      chooseTravelDates: t('web.home.bookATrip.chooseTravelDates'),
      departDate: t('web.home.bookATrip.departDate'),
      returnDate: t('web.home.bookATrip.returnDate'),
      departDateTrip1: t('web.home.bookATrip.departDateTrip1'),
      departDateTrip2: t('web.home.bookATrip.departDateTrip2'),
      search: t('web.home.bookATrip.search'),
      recentSearches: t('web.home.bookATrip.recentSearches'),
      multiCity: t('web.home.bookATrip.multiCity'),
      tripOptions: {
        roundTrip: t('web.home.bookATrip.roundTrip'),
        oneWay: t('web.home.bookATrip.oneWay'),
        multiCity: t('web.home.bookATrip.multiCity'),
      },
      fromRequired: t('web.home.bookATrip.from.required'),
      toRequired: t('web.home.bookATrip.from.toRequired'),
      departDateRequired: t('web.home.bookATrip.departDate.required'),
      returnDateRequired: t('web.home.bookATrip.returnDate.required'),
      multiCityFrom1Required: t('web.home.bookATrip.multiCityFrom1.required'),
      multiCityTo1Required: t('web.home.bookATrip.multiCityTo1.required'),
      multiCityDepartDate1Required: t('web.home.bookATrip.multiCityDepartDate.required'),
      multiCityFrom2Required: t('web.home.bookATrip.multiCityFrom2.required'),
      multiCityTo2Required: t('web.home.bookATrip.multiCityTo2.required'),
      multiCityDepartDate2Required: t('web.home.bookATrip.multiCityReturnDate.required'),
      from: t('web.home.bookATrip.from'),
      to: t('web.home.bookATrip.to'),
      airportCode: airportCode,
    },
    manageBooking: {
      bookingReferenceRequired: t('web.home.bookingReference.required'),
      lastNameRequired: t('web.home.lastName.required'),
      firstNameRequired: t('web.home.firstName.required'),
      text: t('web.home.manageBooking.text'),
      bookingReference: t('web.home.manageBooking.bookingReference'),
      lastName: t('web.home.manageBooking.lastName'),
      firstName: t('web.home.manageBooking.firstName'),
      manageBooking: t('web.home.manageBooking.manageBooking'),
      retrieveMyTrip: t('web.home.manageBooking.retrieveMyTrip'),
    },
    flightStatus: {
      flightNumber: t('web.home.flightStatus.flightNumber'),
      route: t('web.home.flightStatus.route'),
      text: t('web.home.flightStatus.text'),
      fromRequired: t('web.home.flightStatus.from.required'),
      toRequired: t('web.home.flightStatus.to.required'),
      flightNumberRequired: t('web.home.flightStatus.flightNumber.required'),
      departDateRequired: t('web.home.flightStatus.departDate.required'),
      departDate: t('web.home.flightStatus.departDate'),
      searchBy: t('web.home.flightStatus.searchBy'),
      search: t('web.home.flightStatus.search'),
    },
    checkIn: {
      bookingReferenceRequired: t('web.home.bookingReference.required'),
      lastNameRequired: t('web.home.lastName.required'),
      firstNameRequired: t('web.home.firstName.required'),
      text: t('web.home.checkIn.text'),
      bookingReference: t('web.home.checkIn.bookingReference'),
      lastName: t('web.home.checkIn.lastName'),
      firstName: t('web.home.checkIn.firstName'),
      checkIn: t('web.home.checkIn.checkIn'),
    },
  };
  const { setSearchCurrency } = useContext(AppContext);
  return (
    <Box className={`${params?.styles}`}>
      <BookComponent
        handleBookingTripSubmit={handleBookingTripSubmit}
        handleManageMyBookingSubmit={handleManageMyBookingSubmit}
        handleManageMyBookingLink={handleManageMyBookingLink}
        handleCheckInSubmit={handleCheckInSubmit}
        handleFlightStatusSubmit={handleFlightStatusSubmit}
        flightRouteOptions={flightRouteOptions}
        i18nContent={i18nContent}
        campaignCode={params.campaignCode || ''}
        onDepartureChange={(data) => {
          Cookies.set('currency', data.market, {
            domain: getCookieDomain(),
            path: '/',
            expires: 365,
          });
          setSearchCurrency && setSearchCurrency(data.market);
        }}
        pageType="IBE"
      />
    </Box>
  );
};

export default withDatasourceCheck()<BookingProps>(Booking);
