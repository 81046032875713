import {
  AppContext,
  Button,
  ButtonVariant,
  HomeIcon,
  Link,
  Section,
  SvgIconSize,
  Text,
  colors,
  extractPathname,
} from '@hkexpressairwayslimited/ui';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  Field,
  Placeholder,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { envConfig } from 'lib/services/env';
import { useRouter } from 'next/router';
import { ReactNode, useContext } from 'react';

type MainContentProps = {
  params: {
    enableBreadcrumbs: boolean;
  };
} & ComponentProps;

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const MainContent = ({ rendering, params }: MainContentProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { breadcrumbs } = useContext(AppContext);
  const router = useRouter();

  return (
    <Section>
      {((sitecoreContext.route?.fields?.withBreadcrumbs as Field<boolean>)?.value ||
        params.enableBreadcrumbs) && (
        <Breadcrumbs
          maxItems={4}
          separator={<Text color={colors.neutralBorderMain}>/</Text>}
          style={{ marginTop: 14, marginBottom: 24 }}
        >
          {breadcrumbs?.reduce((arr, breadcrumb, index) => {
            const pathname = extractPathname(breadcrumb.href, envConfig.languageList);

            if (index === 0) {
              arr.push(
                <Link key={index} href={`/${router.locale}/`}>
                  <Button noBorder variant={ButtonVariant.Icon} iconSize={SvgIconSize.Desktop}>
                    <HomeIcon />
                  </Button>
                </Link>
              );
            }
            if (pathname && pathname !== '/') {
              arr.push(<Text key={index}>{breadcrumb.title}</Text>);
            }

            return arr;
          }, [] as ReactNode[])}
          <Text>
            {((sitecoreContext.route?.fields?.pageTitle as Field<string>)?.value as string) || ''}
          </Text>
        </Breadcrumbs>
      )}
      <Placeholder name="main-content" rendering={rendering} />
    </Section>
  );
};

export default withDatasourceCheck()<MainContentProps>(MainContent);
