/**
 * @param {import('next').NextConfig} nextConfig
 */
const robotsPlugin = (nextConfig = {}) => {
  return Object.assign({}, nextConfig, {
    async rewrites() {
      return {
        beforeFiles: [
          ...(await nextConfig.rewrites()).beforeFiles,
          // robots route
          {
            source: '/robots.txt',
            destination: '/api/robots',
          },
        ],
      };
    },
  });
};

module.exports = robotsPlugin;
