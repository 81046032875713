import { Box } from '@hkexpressairwayslimited/ui';
import { styled } from '@mui/material';
import { Field, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type TitleProps = ComponentProps & {
  fields: {
    content: Field<string>;
  };
};

const StyledBox = styled(Box)(({ theme }) => ({
  fontSize: '26px',
  lineHeight: '34px',
  fontWeight: 'bolder',
  color: '#702B91',
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    lineHeight: '26px',
  },
}));

const Title = (props: TitleProps): JSX.Element => {
  const fontColor = props.params?.fontColor || '#702B91';
  return (
    <StyledBox sx={{ color: fontColor }} className={`${props.params?.styles}`}>
      <Text field={props.fields?.content} />
    </StyledBox>
  );
};

export default withDatasourceCheck()<TitleProps>(Title);
