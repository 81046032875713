import { AccordionExpandIcon, Box, EPixel } from '@hkexpressairwayslimited/ui';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  styled,
} from '@mui/material';
import { Field, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssRichText } from 'lib/components/JssRichText';

type AccordionProps = ComponentProps & {
  fields: {
    title: Field<string>;
    content: Field<string>;
  };
};

const StyledMuiAccordion = styled(MuiAccordion)(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
  '&:first-of-type': {
    borderTopLeftRadius: EPixel.px16,
    borderTopRightRadius: EPixel.px16,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: EPixel.px16,
    borderBottomRightRadius: EPixel.px16,
  },
  boxShadow: 'none',
  backgroundColor: '#F7F7F7',
  borderRadius: EPixel.px16,
  '&.Mui-expanded': {
    margin: 0,
  },
  '.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 'initial',
  },
  '.MuiAccordionSummary-root': {
    padding: `0 ${EPixel.px24}`,
    [theme.breakpoints.down('md')]: {
      padding: `0 ${EPixel.px16}`,
    },
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    margin: `${EPixel.px12} 0`,
  },
  '.MuiAccordionDetails-root': {
    padding: `0 ${EPixel.px24} ${EPixel.px12} ${EPixel.px24}`,
    [theme.breakpoints.down('md')]: {
      padding: `0 ${EPixel.px16} ${EPixel.px12} ${EPixel.px16}`,
    },
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    width: EPixel.px32,
    height: EPixel.px32,
    color: '#702B91',
    transition: 'none',
  },
  '& p': {
    margin: 0,
  },
}));

const Accordion = (props: AccordionProps): JSX.Element => {
  return (
    <StyledMuiAccordion className={`${props.params?.styles}`}>
      <MuiAccordionSummary expandIcon={<AccordionExpandIcon />}>
        <Box
          sx={{
            color: '#702B91',
            fontWeight: 'bolder',
            fontSize: EPixel.px22,
            lineHeight: EPixel.px32,
          }}
        >
          <Text field={props.fields?.title} />
        </Box>
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        <JssRichText field={props.fields?.content} />
      </MuiAccordionDetails>
    </StyledMuiAccordion>
  );
};

export default withDatasourceCheck()<AccordionProps>(Accordion);
