import { PressRelease as PressReleaseUi } from '@hkexpressairwayslimited/ui';
import {
  Field,
  Link,
  LinkField,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JssRichText } from 'lib/components/JssRichText';

type PressReleaseProps = ComponentProps & {
  fields: {
    date: Field<string>;
    title: LinkField;
    description: Field<string>;
  };
  style?: React.CSSProperties;
};

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const PressRelease = ({ fields, style }: PressReleaseProps): JSX.Element => {
  return (
    <PressReleaseUi
      date={<Text field={fields?.date} />}
      title={<Link field={fields?.title} />}
      description={<JssRichText field={fields?.description} />}
      style={style}
    />
  );
};

export default withDatasourceCheck()<PressReleaseProps>(PressRelease);
